/**
 * basicss overrides
 *
 * any defaults in basicss/basicss/_settings.scss
 * may be overridden...
 *
 * e.g. $prototyping: true;
 */

 $colors: (
  // "main": #FFF, // Reserviert für die Hauptvariable

  "main1": #004c8a,
  "main2": #91bb52,
  "main3": #ac3962,
  "main4": #3086BB, // farbe nicht im Screen

  "blue1": #003a6a,

  "gray1": #919191,
  "gray2": #727272,
  "gray3": #e1e1e1,
  "gray4": #a4a4a4,
  "gray5": #ccdbe8,
  "gray6": #bebcbd,
  "gray7": #252525,

  "white": #FFF,
  "black": #000,

  "link": #000,
  "hover": #000,
  "border": #e9e4e1
);

$gridObject: true;
$constrainObject: true;
$mediaObject: true;

$fontPrimary: 'Noto Sans', sans-serif;
$fontSecondary: 'Noto Sans', sans-serif;

// $baseFontSize: 18px;
// $baseLineHeight: 36px;

// version 2
$baseFontSize: 16px;
$baseLineHeight: 32px;

$baseUnit: 30px;

$headings: (
  '1': (
    'font-size': 40px,
    'line-height': 60px,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'white'),
    'responsive': (
      'lap': (
        'font-size': 60px,
        'line-height': 70px,
      ),
      'desk-wide': (
        'font-size': 70px,
        'line-height': 80px,
      )
    )
  ),
  '2': (
    'font-size': 32px,
    'line-height': 32px*1.2,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'black'),
    'responsive': (
      'lap': (
        'font-size': 40px,
        'line-height': 40px*1.2,
      ),
      'desk-wide': (
        'font-size': 50px,
        'line-height': 50px*1.2,
      )
    )
  ),
  '3': (
    'font-size': 24px,
    'line-height': 24px*1.2,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'black'),
    'responsive': (
      'lap': (
        'font-size': 28px,
        'line-height': 28px*1.2,
      ),
      'desk-wide': (
        'font-size': 30px,
        'line-height': 30px*1.2,
      )
    )
  ),
  '4': (
    'font-size': 22px,
    'line-height': 22px*1.55,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'black'),
    'responsive': (
      'lap': (
        'font-size': 24px,
        'line-height': 24px*1.55,
      ),
      'desk-wide': (
        'font-size': 26px,
        'line-height': 26px*1.55,
      )
    )
  ),
  '5': (
    'font-size': 20px,
    'line-height': 20px*1.65,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'black'),
    'responsive': (
      'lap': (
        'font-size': 20px,
        'line-height': 20px*1.65,
      ),
      'desk-wide': (
        'font-size': 22px,
        'line-height': 22px*1.65,
      )
    )
  ),
  '6': (
    'font-size': 18px,
    'line-height': 18px*1.2,
    'font-family': $fontPrimary,
    'font-weight': 600,
    'letter-spacing': -.02em,
    'color': map-get($colors,'black'),
    // 'responsive': (
    //   'lap': (
    //     'font-size': 19px,
    //     'line-height': 19px*1.2,
    //   ),
    //   'desk-wide': (
    //     'font-size': 20px,
    //     'line-height': 20px*1.2,
    //   )
    // )
  ),
);

$breaks: (
  mob: 376px,
  tab: 481px,
  lap: 721px,
  desk: 1024px,
  desk-wide: 1330px,
  desk-max: 1654px,
);
$constrain: (
  small:    480px,
  medium:   570px,
  large:    1170px,
  max:      1654px,
  limit:    1820px,
);

