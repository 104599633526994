@import 'base';

.play-button{
  display: block;
  width: 90px;
  height: 90px;
  @include maincolor('background-color');
  background-position: center;
  border-radius: 50%;
  outline: 20px solid rgba(0,0,0, .1);
  transition: .3s ease-in-out;
  cursor: pointer;
  @include media(break('lap')){
    width: 110px;
    height: 110px;
  }
  @include media(break('desk-wide')){
    width: 142px;
    height: 142px;
  }
  &:hover{
    outline: 20px solid rgba(0,0,0, .2);
  }
}