@charset "UTF-8";
/**
 * basicss overrides
 *
 * any defaults in basicss/basicss/_settings.scss
 * may be overridden...
 *
 * e.g. $prototyping: true;
 */
/**
 * basicss.scss
 *
 * Author: Marian Friedmann
 *
 */
/* ====================================================
   Reset, Defaults, Mixins
   ==================================================== */
/**
 * Reset, Default settings
 */
/**
 * _settings.scss
 *
 * Author: Marian Friedmann
 */
/**
* Variables Mode
*/
/**
* Default colors
*/
/**
 * Default objects
 */
/**
 * Default helper
 */
/**
 * Default fonts
 */
/**
 * Default font size
 */
/**
 * Default headings
 */
/**
 *  Default spacing
 */
/**
 * Default breakpoints
 */
/**
 * Default spaces
 */
/**
 * Default constrains
 */
/**
 * Arrows
 */
/**
 * Global Variables
 */
/**
 * Mixins
 */
/**
 * _media.scss
 *
 * Author: Marius Scheel
 *
 * Usage:
 * @include media(300px);
 * or @include media(300px, false);
 * or @include media(max-width 300px);
 * or @include media(100px max-width 300px);
 * or @include media(max-width 100px max-width 300px);
 * or @include media(max-width 100px max-width 300px, true);
 *
 */
/**
 * _hdpi.scss
 *
 * Author: Marius Scheel
 *
 * defaults to 1.3 to include Google Nexus 7
 * adapted from bourbon:
 * https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_hidpi-media-query.scss
 * 
 * Usage:
 * @include hdpi {
 *  // your css for hdpi displays
 * };
 *
 * you can use a custom ratio, if you like:
 * @include hdpi(1.5) {
 *  // your css
 * };
 */
/**
 * _retina-image.scss
 *
 * Author: Marius Scheel
 *
 * Usage:
 * .hero {
 *  @include retina-image("../path/to/hero", 200px 400px);
 * }
 *
 * to use a specific filename:
 * .hero {
 *  @include retina-image("../path/to/hero", 200px 400px, "../path/to/retina-hero"); 
 * } 
 *
 * modified version of bourbon's retina-image mixin.
 * The mixin uses a @2x.png retina filename by default.
 * https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_retina-image.scss
 */
/**
 * _font-size.scss
 *
 * Author: Marian Friedmann
 *
 */
/**
 * _px-to-rem.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * .element {
 *   @include px-to-rem(padding, 0 24px)
 * }
 *
 * Output:
 * .element {
 *   padding: 0 24px;
 *   padding: 0 1.5rem;
 * }
 *
 */
/**
 * _clearfix.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * .element {
 *   @include clearfix;
 * }
 *
 */
/**
 * _ellipsis.scss
 *
 * Author: Marian Friedmann
 *
 * ellipsis mixin 
 *
 */
/* ====================================================
   Base Styles
   ==================================================== */
/**
 * _main.scss
 *
 * Author: Marian Friedmann
 *
 */
/**
 * _baseline.scss
 *
 * Author: Marian Friedmann
 *
 */
/**
 * _typo.scss
 *
 * Author: Marian Friedmann
 *
 */
/**
 * _links.scss
 *
 * Author: Marian Friedmann
 *
 */
/**
 * media-src.scss
 *
 * Author: Marian Friedmann
 *
 */
/* ====================================================
   Objects
   ==================================================== */
/**
 * _grid.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <div class="grid">
 *   <div class="grid__item  width--1of1  width-tab--1of3  width-desk--1of4">
 *       ...
 *   </div><!--
 *--><div class="grid__item  width--1of1  width-tab--2of3  width-desk--3of4">
 *     <div class="grid">
 *       <div class="grid__item  width--1of1  width-tab--1of2">
 *           ...
 *       </div><!--
 *    --><div class="grid__item  width--1of1  width-tab--1of2">
 *           ...
 *       </div>
 *     </div>
 *   </div>
 * </div>
 *
 * the example above combines the grid items with the widths from helper/_width.scss
 */
/**
 * Reverse the order of grid items
 */
/**
 * adds a seperator line to the grid
 */
/**
 * Grid items without gutters
 */
/**
 * Grid size variations
 */
/**
 * _btn.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <a class="btn [btn--secondary]" href="#">btn</a>
 * <input type="text" class="btn">
 * <button class="btn">btn</button>
 */
/* Button disguised as a link. */
/**
 * _constrain.scss
 *
 * Author: Marius Scheel
 *
 */
/**
 * _nav.scss
 *
 * Author: Marian Friedmann
 *
 * source: Nav abstraction from csswizardry.com/2011/09/the-nav-abstraction
 *
 * Can be used on an `ol` or `ul` and displays the list items horizontally.
 * Extend it with the following subclasses:
 *
 * - nav--stacked    // displays the list items vertically
 * - nav--banner     // centres the list
 * - nav--block      // expands list items clickable areas
 * - nav--fit        // forces the list to occupy the full width of its parent
 * - nav--keywords   // display as a list of keywords
 * - nav--breadcrumb // display as breadcrumbs
 * - nav--parallel   // display nav items with parallel separator
 *
 * Markup:
 *
 * <ul class="nav">
 *   <li><a href=#>Home</a></li>
 *   <li><a href=#>About</a></li>
 *   <li><a href=#>Portfolio</a></li>
 *   <li><a href=#>Contact</a></li>
 * </ul>
 */
/**
 * 'nav-stacked' displays the list items vertically
 */
/**
 * 'nav-banner' centres the list
 */
/**
 * 'nav--block' expands list items clickable areas with padding
 */
/**
 * 'nav--fit' forces the list to occupy the full width of its parent
 */
/**
 * 'nav--keywords' display as a list of keywords
 */
/**
 * 'nav--breadcrumbs' display as breadcrumbs
 */
/**
 * 'nav--parallel' display with parallel separator
 */
/**
 * _box.scss
 *
 * modified version of:
 * https://github.com/inuitcss/objects.box/blob/master/_objects.box.scss
 *
 */
/**
 * _media.scss
 *
 * displays image and text side by side
 *
 * Example markup:
 *
    <div class="media media--small">
      <div class="media__img"></div>
      <div class="media__body"></div>
    </div>
 *
 * use the `.media--rev` modifier to reverse the the image location and put it to the right
 * use the `.media--small` modifier, to decrease the gutter width between image and content
 *
 * for more information have a look at:
 * stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 *
 */
/**
 * _bare-list.scss
 *
 */
/**
 * _ui-list.scss
 *
 * based on Harry Roberts ui-list object:
 * https://github.com/inuitcss/objects.ui-list/blob/master/_objects.ui-list.scss
 *
 * creates blocky items, can be used with lists or the corresponding markup:
 *
 * <div class="ui-list">
 *  <div class="ui-list__item"></div>
 *  <div class="ui-list__item"></div>
 *  <div class="ui-list__item"></div>
 * </div>
 *
 * or:
 *
 * <ul class="ui-list ui-list--small">
 *  <li></li>
 *  <li></li>
 *  <li></li>
 * </ul>
 *
 * can optionally be used with --separator and --border, where --separator
 * adds borders to the list element and --border to the list itself
 *
 * comes with different size modifiers
 *
 */
/**
 * Modifiers for sizing the list
 */
/**
 * _arrow.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <div class="arrow arrow--top-left">
 *  ...
 * </div>
 *
 */
/**
 * Arrow Top
 */
/**
 * Arrow Bottom
 */
/**
 * Arrow Left
 */
/**
 * Arrow Right
 */
/**
 * _icon.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <i class="icon [icon--nav-toggle]"></i>
 *
 */
/**
 * Alle icons werden als .svg exportiert unter
 * /assets/images/icons abgelegt und von grunt-svgmin
 * und grunt-grunticon weiterverarbeitet.
 *
 * Output unter /assets/images/icons/grunticon
 */
/**
 * Beispiel für ein Icon
 *
 * .icon--mobile-nav-toggle {
 *   @include background-svg("../images/nav-toggle");
 * }
 */
/* ====================================================
   Helper Classes
   ==================================================== */
/**
 * _spacing.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <div class="space--top  space-tab--top-double">
 *   ...
 * </div>
 *
 */
/**
 * Basic & spacing
 */
/**
 * _width.scss
 *
 * Author: Marian Friedmann
 *
 * Usage:
 * <div class="grid">
 *   <div class="grid__item  width--1of1  width-tab--1of3  width-desk--1of4">
 *     ...
 *   </div>
 *   <div class="grid__item  width--1of1  width-tab--2of3  width-desk--3of4">
 *     ...
 *   </div>
 * </div>
 *
 */
/**
 * _typo.scss
 *
 * Author: Marian Friedmann
 *
 * Alt Text Styles.
 *
 * ## alignment
 * - text--left
 * - text--right
 * - text--center
 *
 * ## utilities
 * - text--secondary
 * - text--inherit-color
 * - text--no-wrap
 * - text--large
 *
 * Usage:
 * <div class="text--large">
 *   <h1>Lorem Ipsum</h1>
 *   <p>Lorem ipsum</p>
 * </div>
 *
 */
/**
 * Prevent text from wrapping
 */
/**
 * Display text in secondary font
 */
/**
 * Display larger text
 */
/**
* _color.scss
*/
.play-button {
  display: block;
  width: 90px;
  height: 90px;
  background-color: #004c8a;
  background-color: var(--main);
  background-position: center;
  border-radius: 50%;
  outline: 20px solid rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (min-width: 721px) {
  .play-button {
    width: 110px;
    height: 110px;
  }
}
@media screen and (min-width: 1330px) {
  .play-button {
    width: 142px;
    height: 142px;
  }
}
.play-button:hover {
  outline: 20px solid rgba(0, 0, 0, 0.2);
}