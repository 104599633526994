@mixin maincolor($selector:'background-color') {
  #{$selector}: color('main1');
  #{$selector}: var(--main);
}

@mixin colorClass($scope: '') {
  @if $scope != '' { $scope: -#{$scope}; }
  %c#{$scope}--main {
    @include maincolor('color');
  }
  %bg#{$scope}--main {
    @include maincolor('background-color');
  }
  @if $addCss and $colorHelper {
    .c#{$scope}--main {
      @extend %c#{$scope}--main;
    }
    .bg#{$scope}--main {
      @extend %bg#{$scope}--main;
    }
  }
}
@include colorClass();

@each $key,$break in $breaks {
  @include media($break) {
    @include colorClass($key);
  }
}